import { useState } from "react";
import { Currency } from "../../billing/components";
import { getEstimatedPayoutFromData } from "../../jason-proof-of-concept/other/actions/getEstimatedPayout";
import { TBody, THead, Table, Td, Th, Tr } from "../../layout/table";
import { EditDealValueModal } from "./modals/edit-deal-value-modal";
import { Company } from "../domain/company";
import { EditGrossFeeModal } from "./modals/edit-gross-fee-modal";
import { EditAffiliateFeeModal } from "./modals/edit-affiliate-fee-modal";
import { UserCompany } from "../../user-companies/domain/user-company";
import { User } from "../../jason-proof-of-concept/users/domain/user";
import { EditUserCommissionModal } from "./modals/edit-user-commission-modal";
import { startCase, sumBy } from "lodash";
import { classNames } from "../../billing/utils";
import OptionsDropDown from "../../layout/options-dropdown";
import { CreatePaymentModal } from "./modals/create-payment-modal";
import { Transaction } from "../../transactions/domain/transaction";
import { Payment } from "../../payments/domain/payment";
import { RoleGroup } from "../../role-groups/domain/role-group";
import { CreatePaymentRequestModal } from "./modals/create-payment-request-modal";
import { PaymentRequest, PaymentRequestStatus } from "../../payment-requests/domain/payment-request";
import SwalNeogenFire from "../../layout/swal-neogen";
import { getDarkMode } from "../../sections/admin/magic-links/magic-links";
import { useUpdatePaymentRequest } from "../../payment-requests/hooks/use-update-payment-request";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import ErrorSection, { AlertMessage } from "../../layout/error-section";

export const Billing = ({
    applicationData,
    company,
    onCompanyUpdated,
    userCompanies,
    users,
    onUserCompanyUpdated,
    transactions,
    onPaymentCreated,
    onPaymentRequestCreated,
    onPaymentRequestUpdated,
    roleGroups,
    paymentRequests,
    hedgeFunds,
    reFetchPaymentRequests,
    reFetchTransactions,
}: {
    applicationData: Record<string, any>;
    company: Company;
    onCompanyUpdated?: (updatedCompany: Company) => void;
    userCompanies: UserCompany[];
    users: User[];
    onUserCompanyUpdated?: (updatedCompany: UserCompany) => void;
    transactions: Transaction[];
    onPaymentCreated?: (payment: Payment) => void;
    onPaymentRequestCreated?: (paymentRequest: PaymentRequest) => void;
    onPaymentRequestUpdated?: (paymentRequest: PaymentRequest) => void;
    roleGroups: RoleGroup[];
    paymentRequests: PaymentRequest[];
    hedgeFunds?: Company[];
    reFetchPaymentRequests?: () => void;
    reFetchTransactions?: () => void;
}) => {
    const authToken = getAuthTokenNoThrow() || "no-auth";
    const [showEditGrossFeeModal, setShowEditGrossFeeModal] = useState(false);
    const [showEditAffiliateFeeModal, setShowEditAffiliateFeeModal] = useState(false);
    const [showEditCommissionModalForUserId, setShowEditCommissionModalForUserId] = useState<string | undefined>();
    const [activeTab, setActiveTab] = useState("Overview");
    const [showCapturePaymentModal, setShowCapturePaymentModal] = useState<Partial<Payment> | undefined>();
    const [showNewPaymentRequestModal, setShowNewPaymentRequestModalWithValues] = useState<
        (Partial<PaymentRequest> & { maxAmount?: number }) | undefined
    >();

    const estimatedAmount = getEstimatedPayoutFromData({ data: applicationData });
    const irsPayout = company.finalRefundAmount || 0;
    const grossFeeAmount = company.totalFeeAmount || 0;
    const clearErcFeePercentage =
        company.totalFeeType === "percentage"
            ? Number.parseFloat((company.totalFeePercent || 0).toFixed(2))
            : ((grossFeeAmount / irsPayout) * 100).toFixed(2);
    const clearErcFeeAmount =
        company.totalFeeType === "amount" ? grossFeeAmount : ((company.totalFeePercent || 0) / 100) * irsPayout;

    const allUsers = [...(users || [])];

    const members = [
        ...((userCompanies || [])
            .map((userCompany) => {
                const user = (users || []).find((u) => u.id === userCompany.ercUserId);
                const amountDue = ((userCompany.commissionPercentage || 0) / 100) * clearErcFeeAmount;
                const amountPaid = user
                    ? sumBy(
                          transactions.filter((t) => t.toUserId === user.id || t.fromUserId === user.id),
                          (t) => (t.toUserId === user.id ? t.amount : -t.amount),
                      )
                    : 0;
                const amountPending = user
                    ? sumBy(
                          paymentRequests.filter(
                              (pr) =>
                                  (pr.toUserId === user.id || pr.fromUserId === user.id) &&
                                  pr.status === PaymentRequestStatus.Pending,
                          ),
                          (pr) => (pr.toUserId === user.id ? pr.amount : -pr.amount),
                      )
                    : 0;
                const balance = amountDue - amountPaid - amountPending;
                return {
                    userCompany,
                    user,
                    amountDue,
                    amountPending,
                    amountPaid,
                    balance,
                };
            })
            .filter((m) => !!m.user && !!m.userCompany) as {
            user: User;
            userCompany: UserCompany;
            amountDue: number;
            amountPending: number;
            amountPaid: number;
            balance: number;
        }[]),
    ];

    const memberToEdit = members.find((m) => m.user.id === showEditCommissionModalForUserId);

    const irsDiff = estimatedAmount - (company.finalRefundAmount || 0);
    const irsAmountPaid = sumBy(
        transactions.filter((t) => (t.from === "IRS" && t.toClearErc) || (t.to === "IRS" && t.fromClearErc)),
        (t) => (t.from === "IRS" ? t.amount : -t.amount),
    );
    const irsAmountPending = sumBy(
        paymentRequests.filter(
            (pr) =>
                ((pr.from === "IRS" && pr.toClearErc) || (pr.to === "IRS" && pr.fromClearErc)) &&
                pr.status === PaymentRequestStatus.Pending,
        ),
        (pr) => (pr.from === "IRS" ? pr.amount : -pr.amount),
    );
    const irsBalance = -irsPayout + irsAmountPaid + irsAmountPending;

    const feeAmountPaid = sumBy(
        transactions.filter(
            (t) => (t.fromCompanyId === company.id && t.toClearErc) || (t.toCompanyId === company.id && t.fromClearErc),
        ),
        (t) => (t.fromCompanyId === company.id ? t.amount : -t.amount),
    );
    const feeAmountPending = sumBy(
        paymentRequests.filter(
            (pr) =>
                ((pr.fromCompanyId === company.id && pr.toClearErc) ||
                    (pr.toCompanyId === company.id && pr.fromClearErc)) &&
                pr.status === PaymentRequestStatus.Pending,
        ),
        (pr) => (pr.fromCompanyId === company.id ? pr.amount : -pr.amount),
    );
    const feeBalance = -clearErcFeeAmount + feeAmountPaid + feeAmountPending;

    const updatePaymentRequestMutation = useUpdatePaymentRequest();

    const tabs = [{ name: "Overview" }, { name: "Payment requests" }, { name: "Transactions" }];

    const totalCommissionPercentage = sumBy(members.map((m) => m.userCompany.commissionPercentage || 0));

    return (
        <div className="p-4">
            {showEditGrossFeeModal && (
                <EditGrossFeeModal
                    applicationData={applicationData}
                    company={company}
                    onClose={() => {
                        setShowEditGrossFeeModal(false);
                    }}
                    onCompanyUpdated={(updatedCompany) => {
                        setShowEditGrossFeeModal(false);
                        if (onCompanyUpdated) {
                            onCompanyUpdated(updatedCompany);
                        }
                        if (reFetchPaymentRequests) {
                            reFetchPaymentRequests();
                        }
                        if (reFetchTransactions) {
                            reFetchTransactions();
                        }
                    }}
                    hedgeFunds={hedgeFunds}
                />
            )}
            {showEditAffiliateFeeModal && (
                <EditAffiliateFeeModal
                    applicationData={applicationData}
                    company={company}
                    irsPayout={irsPayout}
                    clearErcGrossFee={clearErcFeeAmount}
                    onClose={() => {
                        setShowEditAffiliateFeeModal(false);
                    }}
                    onCompanyUpdated={(updatedCompany) => {
                        setShowEditAffiliateFeeModal(false);
                        if (onCompanyUpdated) {
                            onCompanyUpdated(updatedCompany);
                        }
                    }}
                />
            )}
            {showEditCommissionModalForUserId && memberToEdit && (
                <EditUserCommissionModal
                    clearErcFee={clearErcFeeAmount}
                    company={company}
                    dealValue={irsPayout}
                    user={memberToEdit.user}
                    userCompany={memberToEdit.userCompany}
                    onClose={() => {
                        setShowEditCommissionModalForUserId(undefined);
                    }}
                    onUpdated={(updatedUserCompany) => {
                        setShowEditCommissionModalForUserId(undefined);
                        if (onUserCompanyUpdated) {
                            onUserCompanyUpdated(updatedUserCompany);
                        }
                    }}
                />
            )}
            {showNewPaymentRequestModal && (
                <CreatePaymentRequestModal
                    companyId={company.id}
                    defaultValues={showNewPaymentRequestModal}
                    fromUser={showNewPaymentRequestModal.fromUser || undefined}
                    fromCompany={showNewPaymentRequestModal.fromCompany || undefined}
                    toUser={showNewPaymentRequestModal.toUser || undefined}
                    toCompany={showNewPaymentRequestModal.toCompany || undefined}
                    onClose={() => {
                        setShowNewPaymentRequestModalWithValues(undefined);
                    }}
                    maxAmount={showNewPaymentRequestModal.maxAmount}
                    onCreate={(paymentRequest) => {
                        setShowNewPaymentRequestModalWithValues(undefined);
                        if (onPaymentRequestCreated) {
                            onPaymentRequestCreated(paymentRequest);
                        }
                    }}
                />
            )}
            {showCapturePaymentModal && (
                <CreatePaymentModal
                    companyId={company.id}
                    defaultValues={showCapturePaymentModal}
                    fromUser={showCapturePaymentModal.fromUser || undefined}
                    fromCompany={showCapturePaymentModal.fromCompany || undefined}
                    toUser={showCapturePaymentModal.toUser || undefined}
                    toCompany={showCapturePaymentModal.toCompany || undefined}
                    onClose={() => {
                        setShowCapturePaymentModal(undefined);
                    }}
                    onPaymentCaptured={(payment) => {
                        setShowCapturePaymentModal(undefined);
                        if (onPaymentCreated) {
                            onPaymentCreated(payment);
                        }
                    }}
                />
            )}

            <div className="lg:block">
                <div className="border-b border-gray-200">
                    <nav className="flex space-x-8">
                        {tabs.map((tab) => (
                            <span
                                key={tab.name}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setActiveTab(tab.name);
                                }}
                                className={classNames(
                                    tab.name === activeTab
                                        ? "border-black text-black"
                                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                                    "whitespace-nowrap py-3 px-1 border-b-2 font-medium text-sm",
                                )}
                            >
                                {tab.name}
                            </span>
                        ))}
                    </nav>
                </div>
            </div>
            {activeTab === "Overview" && (
                <>
                    {totalCommissionPercentage > 35 && (
                        <AlertMessage message={"Member commission has exceeded 35% - Please review"} />
                    )}
                    <Table>
                        <TBody>
                            <Tr>
                                <Td style={{ width: 200, color: "gray", verticalAlign: "top" }}>
                                    Estimated deal value
                                </Td>
                                <Td>
                                    <b>
                                        <Currency amount={estimatedAmount} />
                                    </b>
                                </Td>
                            </Tr>
                            <Tr>
                                <Td style={{ width: 200, color: "gray", verticalAlign: "top" }}>Final refund amount</Td>
                                <Td style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    <b>
                                        <Currency amount={company.finalRefundAmount || 0} />
                                    </b>
                                    {estimatedAmount > 0 && (
                                        <>
                                            <br />
                                            <span style={{ color: "gray" }}>
                                                <Currency amount={Math.abs(irsDiff)} /> (
                                                {(Math.abs(irsDiff / estimatedAmount) * 100).toFixed(1)}%){" "}
                                                {irsDiff > 0 ? "less" : "more"} than estimated amount
                                            </span>
                                        </>
                                    )}
                                </Td>
                                <Td style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    <OptionsDropDown
                                        options={[
                                            {
                                                label: "Edit amount",
                                                onClick: () => {
                                                    setShowEditGrossFeeModal(true);
                                                },
                                            },
                                        ]}
                                    />
                                </Td>
                            </Tr>
                        </TBody>
                    </Table>
                    <Table>
                        <THead>
                            <Tr>
                                <Th>Item</Th>
                                <Th>Due</Th>
                                <Th>Paid</Th>
                                <Th>Pending</Th>
                                <Th>Balance</Th>
                                <Th>Actions</Th>
                            </Tr>
                        </THead>
                        <TBody>
                            <Tr>
                                <Td style={{ width: 200, verticalAlign: "top" }}>ClearERC gross fee</Td>
                                <Td>
                                    <div className="flex flex-col">
                                        <div>
                                            <b>
                                                <Currency amount={clearErcFeeAmount} />
                                            </b>{" "}
                                            {clearErcFeePercentage && !isNaN(clearErcFeePercentage as any) && (
                                                <span>({clearErcFeePercentage}%)</span>
                                            )}
                                            {company.totalFeeType && <span> configured as {company.totalFeeType}</span>}
                                        </div>
                                        {clearErcFeePercentage && !isNaN(clearErcFeePercentage as any) && (
                                            <span style={{ color: "gray" }}>
                                                ({clearErcFeePercentage || 0}% of <Currency amount={irsPayout} /> - The
                                                final refund amount)
                                            </span>
                                        )}
                                    </div>
                                </Td>
                                <Td>
                                    <Currency amount={feeAmountPaid} />
                                </Td>
                                <Td>
                                    <Currency amount={feeAmountPending} />
                                </Td>
                                <Td>
                                    <Currency amount={feeBalance} />
                                </Td>
                                <Td style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    <OptionsDropDown
                                        options={[
                                            {
                                                label: "Edit fee",
                                                onClick: () => {
                                                    setShowEditGrossFeeModal(true);
                                                },
                                            },
                                            {
                                                label: "Create payment request",
                                                onClick: () => {
                                                    setShowNewPaymentRequestModalWithValues({
                                                        description: "Fee payment",
                                                        amount: feeBalance * -1,
                                                        fromCompanyId: company.id,
                                                        fromCompany: company,
                                                        toClearErc: true,
                                                    });
                                                },
                                            },
                                            {
                                                label: "Create payment",
                                                onClick: () => {
                                                    setShowCapturePaymentModal({
                                                        description: "Fee payment",
                                                        amount: feeBalance * -1,
                                                        fromCompanyId: company.id,
                                                        fromCompany: company,
                                                        toClearErc: true,
                                                    });
                                                },
                                            },
                                        ]}
                                    />
                                </Td>
                            </Tr>
                        </TBody>
                    </Table>
                    <Table>
                        <THead>
                            <Tr>
                                <Th>User payouts</Th>
                                <Th>Due</Th>
                                <Th>Paid</Th>
                                <Th>Pending</Th>
                                <Th>Balance</Th>
                                <Th>Actions</Th>
                            </Tr>
                            <Tr>
                                <Th>Total</Th>
                                <Th>
                                    <Currency amount={sumBy(members.map((m) => m.amountDue || 0))} /> (
                                    {sumBy(members.map((m) => m.userCompany.commissionPercentage || 0))}%)
                                </Th>
                                <Th>
                                    <Currency amount={sumBy(members.map((m) => m.amountPaid || 0))} />
                                </Th>
                                <Th>
                                    <Currency amount={sumBy(members.map((m) => m.amountPending || 0))} />
                                </Th>
                                <Th>
                                    <Currency amount={sumBy(members.map((m) => m.balance || 0))} />
                                </Th>
                                <Th />
                            </Tr>
                        </THead>
                        <TBody>
                            {members.map(({ user, userCompany, amountDue, amountPending, amountPaid, balance }) => {
                                const roleGroup = roleGroups.find((rg) => userCompany.roleGroupId === rg.id);
                                return (
                                    <Tr key={userCompany.id}>
                                        <Td>
                                            <div className="flex flex-col">
                                                <div>
                                                    {user?.firstName} {user?.lastName}
                                                </div>
                                                <div>{user.email}</div>
                                                <div style={{ color: "gray" }}>{roleGroup?.name || "Member"}</div>
                                            </div>
                                        </Td>
                                        <Td>
                                            <Currency amount={amountDue} /> ({userCompany.commissionPercentage || 0}%)
                                        </Td>
                                        <Td>
                                            <Currency amount={amountPaid} />
                                        </Td>
                                        <Td>
                                            <Currency amount={amountPending} />
                                        </Td>
                                        <Td>
                                            <Currency amount={balance} />
                                        </Td>
                                        <Td style={{ paddingTop: 0, paddingBottom: 0 }}>
                                            <OptionsDropDown
                                                options={[
                                                    {
                                                        label: "Edit commission",
                                                        onClick: () => setShowEditCommissionModalForUserId(user.id),
                                                    },
                                                    {
                                                        label: "Create payment request",
                                                        onClick: () => {
                                                            setShowNewPaymentRequestModalWithValues({
                                                                description: `${roleGroup?.name} commission payout`,
                                                                fromClearErc: true,
                                                                toUser: user,
                                                                toUserId: user.id,
                                                                amount: balance,
                                                                maxAmount: balance,
                                                            });
                                                        },
                                                    },
                                                    {
                                                        label: "Create payment",
                                                        onClick: () => {
                                                            setShowCapturePaymentModal({
                                                                description: `${roleGroup?.name} commission payout`,
                                                                fromClearErc: true,
                                                                toUser: user,
                                                                toUserId: user.id,
                                                                amount: balance,
                                                            });
                                                        },
                                                    },
                                                ]}
                                            />
                                        </Td>
                                    </Tr>
                                );
                            })}
                        </TBody>
                    </Table>
                </>
            )}
            {activeTab === "Transactions" && (
                <div>
                    <Table>
                        <THead>
                            <Tr>
                                <Th>Description</Th>
                                <Th>Date</Th>
                                <Th>From</Th>
                                <Th>To</Th>
                                <Th>Amount</Th>
                                <Th />
                            </Tr>
                        </THead>
                        <TBody>
                            {transactions.length === 0 && (
                                <Tr>
                                    <Td colSpan={5} style={{ textAlign: "center" }}>
                                        No transactions yet
                                    </Td>
                                </Tr>
                            )}
                            {transactions &&
                                transactions.length > 0 &&
                                transactions.map((t) => {
                                    const toUser = t.toUserId ? allUsers.find((u) => u.id === t.toUserId) : undefined;
                                    const toUserCompany = toUser
                                        ? (userCompanies || []).find(
                                              (uc) => uc.companyId === company.id && uc.ercUserId === toUser.id,
                                          )
                                        : undefined;
                                    const toUserRole =
                                        toUser && toUserCompany
                                            ? roleGroups.find((rg) => toUserCompany.roleGroupId === rg.id)
                                            : undefined;

                                    const fromUser = t.fromUserId
                                        ? allUsers.find((u) => u.id === t.fromUserId)
                                        : undefined;
                                    const fromUserCompany = fromUser
                                        ? (userCompanies || []).find(
                                              (uc) => uc.companyId === company.id && uc.ercUserId === fromUser.id,
                                          )
                                        : undefined;
                                    const fromUserRole =
                                        fromUser && fromUserCompany
                                            ? roleGroups.find((rg) => fromUserCompany.roleGroupId === rg.id)
                                            : undefined;

                                    return (
                                        <Tr key={t.id}>
                                            <Td>{t.description}</Td>
                                            <Td>{t.date.toDateString()}</Td>
                                            <Td>
                                                {t.fromClearErc ? (
                                                    "ClearERC"
                                                ) : fromUser ? (
                                                    <div className="flex flex-col">
                                                        <div>
                                                            {fromUser?.firstName} {fromUser?.lastName}
                                                        </div>
                                                        <div style={{ color: "gray" }}>{fromUserRole?.name}</div>
                                                    </div>
                                                ) : (
                                                    t.from || t.fromCompany?.name
                                                )}
                                            </Td>
                                            <Td>
                                                {t.toClearErc ? (
                                                    "ClearERC"
                                                ) : toUser ? (
                                                    <div className="flex flex-col">
                                                        <div>
                                                            {toUser?.firstName} {toUser?.lastName}
                                                        </div>
                                                        <div style={{ color: "gray" }}>{toUserRole?.name}</div>
                                                    </div>
                                                ) : (
                                                    t.to || t.toCompany?.name
                                                )}
                                            </Td>
                                            <Td>
                                                <Currency amount={t.amount} />
                                            </Td>
                                            <Td>
                                                <OptionsDropDown
                                                    options={[
                                                        {
                                                            label: "Create reversal request",
                                                            onClick: () => {
                                                                setShowNewPaymentRequestModalWithValues({
                                                                    description: `Reversal: ${t.description}`,
                                                                    amount: t.amount,
                                                                    from: t.to || undefined,
                                                                    fromClearErc: t.toClearErc || undefined,
                                                                    fromCompanyId: t.toCompanyId || undefined,
                                                                    fromCompany: t.toCompany || undefined,
                                                                    fromUserId: t.toUserId || undefined,
                                                                    to: t.from || undefined,
                                                                    toClearErc: t.fromClearErc || undefined,
                                                                    toCompanyId: t.fromCompanyId || undefined,
                                                                    toCompany: t.fromCompany || undefined,
                                                                    toUserId: t.fromUserId || undefined,
                                                                });
                                                            },
                                                        },
                                                        {
                                                            label: "Reverse transaction",
                                                            onClick: () => {
                                                                setShowCapturePaymentModal({
                                                                    description: `Reversal: ${t.description}`,
                                                                    amount: t.amount,
                                                                    from: t.to || undefined,
                                                                    fromClearErc: t.toClearErc || undefined,
                                                                    fromCompanyId: t.toCompanyId || undefined,
                                                                    fromCompany: t.toCompany || undefined,
                                                                    fromUserId: t.toUserId || undefined,
                                                                    to: t.from || undefined,
                                                                    toClearErc: t.fromClearErc || undefined,
                                                                    toCompanyId: t.fromCompanyId || undefined,
                                                                    toCompany: t.fromCompany || undefined,
                                                                    toUserId: t.fromUserId || undefined,
                                                                });
                                                            },
                                                        },
                                                    ]}
                                                />
                                            </Td>
                                        </Tr>
                                    );
                                })}
                        </TBody>
                    </Table>
                </div>
            )}
            {activeTab === "Payment requests" && (
                <div>
                    <Table>
                        <THead>
                            <Tr>
                                <Th>Description</Th>
                                <Th>Status</Th>
                                <Th>Requested payment date</Th>
                                <Th>From</Th>
                                <Th>To</Th>
                                <Th>Amount</Th>
                                <Th>Actions</Th>
                            </Tr>
                        </THead>
                        <TBody>
                            {paymentRequests.length === 0 && (
                                <Tr>
                                    <Td colSpan={7} style={{ textAlign: "center" }}>
                                        No payment requests yet
                                    </Td>
                                </Tr>
                            )}
                            {paymentRequests.length > 0 &&
                                paymentRequests.map((pr) => {
                                    return (
                                        <Tr key={pr.id}>
                                            <Td>{pr.description}</Td>
                                            <Td>{pr.status ? startCase(pr.status) : "-"}</Td>
                                            <Td>{pr.requestedPaymentDate?.toDateString()}</Td>
                                            <Td>
                                                {pr.fromClearErc
                                                    ? "ClearErc"
                                                    : pr.from ||
                                                      pr.fromCompany?.name ||
                                                      [pr.fromUser?.firstName, pr.fromUser?.lastName].join(" ")}
                                            </Td>
                                            <Td>
                                                {pr.toClearErc
                                                    ? "ClearErc"
                                                    : pr.to ||
                                                      pr.toCompany?.name ||
                                                      [pr.toUser?.firstName, pr.toUser?.lastName].join(" ")}
                                            </Td>
                                            <Td>
                                                <Currency amount={pr.amount} />
                                            </Td>
                                            <Td>
                                                <OptionsDropDown
                                                    options={[
                                                        {
                                                            label: "Create payment",
                                                            disabled: pr.status !== PaymentRequestStatus.Pending,
                                                            onClick: () => {
                                                                setShowCapturePaymentModal({
                                                                    description: pr.description || "",
                                                                    amount: pr.amount,
                                                                    paymentRequestId: pr.id,
                                                                    from: pr.from || undefined,
                                                                    fromUser: pr.fromUser || undefined,
                                                                    fromClearErc: pr.fromClearErc || false,
                                                                    fromCompanyId: pr.fromCompanyId || undefined,
                                                                    fromCompany: pr.fromCompany,
                                                                    to: pr.to || undefined,
                                                                    toUser: pr.toUser || undefined,
                                                                    toClearErc: pr.toClearErc || false,
                                                                    toCompanyId: pr.toCompanyId || undefined,
                                                                    toCompany: pr.toCompany,
                                                                    notes: pr.notes || "",
                                                                });
                                                            },
                                                        },
                                                        {
                                                            label: "Cancel",
                                                            disabled: pr.status !== PaymentRequestStatus.Pending,
                                                            onClick: () => {
                                                                SwalNeogenFire({
                                                                    darkMode: getDarkMode(),
                                                                    title: "Cancel payment request?",
                                                                    text: "Are you sure you want to cancel this payment request?",
                                                                    icon: "info",
                                                                    showCancelButton: true,
                                                                    confirmButtonText: "Yes",
                                                                    cancelButtonText: "No",
                                                                    showLoaderOnConfirm: true,
                                                                }).then(async (result) => {
                                                                    if (result.isConfirmed) {
                                                                        const updatedPaymentRequest =
                                                                            await updatePaymentRequestMutation.mutateAsync(
                                                                                {
                                                                                    authToken,
                                                                                    id: pr.id,
                                                                                    data: {
                                                                                        status: PaymentRequestStatus.Cancelled,
                                                                                    },
                                                                                },
                                                                            );
                                                                        if (
                                                                            updatedPaymentRequest &&
                                                                            onPaymentRequestUpdated
                                                                        ) {
                                                                            onPaymentRequestUpdated(
                                                                                updatedPaymentRequest,
                                                                            );
                                                                        }
                                                                    }
                                                                });
                                                            },
                                                        },
                                                    ]}
                                                />
                                            </Td>
                                        </Tr>
                                    );
                                })}
                        </TBody>
                    </Table>
                </div>
            )}
        </div>
    );
};
